<div class="home_warper ">
  <div class="hm_searching">
    <a routerLink=""><img src="assets/img/logo.png" class="hm_logo" alt=""></a>
    <div class="hm_seachfrom">
      <form>
        <div class="form-group">
          <span class="sr_backiocn lg-hide"><i class="fa fa-long-arrow-left"></i></span>
          <span class="icon-span"><i class="fa fa-search custom-icon"></i></span>
          <ng-select class="home-select" #ngSelect bindLabel="name" placeholder="Enter Company Name or Trading Symbol"
            [searchable]="true" [ngModelOptions]="{standalone: true}"
            (search)="searchValue=$event?.term;getSearchData()" [clearable]="true"
            (change)="redirectToRoute(searchValue)" [(ngModel)]="searchValue" [isOpen]="isOpenSearchBar"
       (focus)="onFocus();" (blur)="isOpenSearchBar=false" (clear)="onClear()" (keyup.enter)="redirectToRoute(searchValue)" (click)="redirectToRoute(searchValue)">
            <ng-container *ngIf="searchValue">
              <ng-container *ngFor="let item of select2Model">
                <ng-option [value]="item?.value.symbol">
                  <img
                    [src]="item.changePercent > 0 ? '../../assets/img/market_up.png' : item.changePercent < 0 ? '../../assets/img/market_down.png' : '../../assets/img/no_dataupdate.png' ">
                  <a class="a-no-decore" (click)="redirectToRoute(item?.value)" (keyup.enter)="redirectToRoute(item?.value)" (change)="redirectToRoute(item?.value)" href="javascript:void(0);">
                   <strong>{{item?.value.symbol}}</strong> <small>{{item.label}}</small>
                  </a>
                </ng-option>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!searchValue && trendingList.length > 0">
              <ng-option><span class="tranding_text">Trending Today</span></ng-option>
              <ng-container *ngFor="let item of trendingList">
                <ng-option [value]="item?.value.symbol">
                  <img
                    [src]="item.changePercent > 0 ? '../../assets/img/market_up.png' : item.changePercent < 0 ? '../../assets/img/market_down.png' : '../../assets/img/no_dataupdate.png' ">
                  <a class="a-no-decore"
                    (change)="redirectToRoute(item?.value)" href="javascript:void(0);">
                 <strong>{{item?.value.symbol}}</strong>
                  </a>
                </ng-option>
              </ng-container>
            </ng-container>
          </ng-select>
        </div>
        <div class="button-group">
          <button type="submit" [disabled]="isLoading" class="btn btn-light" (submit)="getSearchData()">{{!isLoading?
            'Search':'Searching..'}}</button>
          <a [routerLink]="['/company-profile', randomSymbol]" (click)="openRandomComp()">
            <button type="button"  class="btn btn-light" (click)="someMethod()">Explore</button>
            <!-- <button type="button" (click)="openRandomComp()" class="btn btn-light">
              {{!Loading?'Explore':'Loading...'}}
              </button> -->
          </a>
        </div>
      </form>
    </div>
  </div>

  <!-- <div class="container" >
  <div class="row">
    <div class="col l12 s12 p-0">
      <table #data datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table row-border hover" >
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Company Name</th>
            <th scope="col">Symbol</th>
            <th scope="col">exchange</th>
            <th scope="col">Acion</th>
          </tr>
        </thead>
        <tbody >
          <tr *ngFor="let item of model; let i=index">
            <td scope="row">{{ i+1 }}</td>
            <td>{{ item.companyName }}</td>
            <td>{{ item.symbol.symbol }}</td>
            <td>{{ item.exchange }}</td>

            <td>
              <button type="button" [routerLink]="['/company-profile', item.symbol.symbol]"
              routerLinkActive="router-link-active"  class="btn btn-info">View</button>
              </td>

          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div> -->
</div>

<div class="message_btn_fx">
  <button class="message_btn" type="button" data-toggle="modal" (click)="feedbackInput = '';"
    data-target="#message_bottom" (click)="gotoLoginPage()">
    <img src="../../assets/img/asset.png">
  </button>
</div>

<div class="modal fade add_queto_modal message_bottom" id="message_bottom" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md mob" role="document">
    <div class="modal-content" *ngIf="isLoggedIn">
      <div class="modal-header">
        <h2>We’d love to hear from you as we continue to improve our product!</h2>
        <button type="button" (click)="this.feedbackInput=''" class="close" data-dismiss="modal" aria-label="Close">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <form> -->
        <div class="form_inner">
          <textarea [(ngModel)]="feedbackInput" class="form-control"
            placeholder="Please let us know your thoughts..."></textarea>
        </div>
        <div class="add_termactin">
          <button type="button" data-dismiss="modal" [disabled]="!feedbackInput" aria-label="Close"
            (click)="saveFeedback()" class="btn btn-success">Submit</button>
          <button type="button" data-dismiss="modal" (click)="this.feedbackInput=''" aria-label="Close" class="btn btn-secondary">Cancel</button>
        </div>
        <!-- </form> -->
      </div>
    </div>
    <!-- <div class="modal-content" *ngIf="!isLoggedIn">
      <div class="modal-header">
        <h2>You are not logged in . You must be first login. </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span><i class="fa fa-times" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="add_termactin">
          <button type="button" data-dismiss="modal" aria-label="Close" [routerLink]="['/login']"
            class="btn btn-success">Login</button>
        </div>
      </div>
    </div> -->
  </div>
</div>
<app-news-new class="lg-hide md-hide home_page"></app-news-new>
