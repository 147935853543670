<div class="news-content">
  <div class="container">
    <div class="news-date" *ngIf="!isSuperUser">{{ todayDate | dateSuffix }}</div>
    <div class="news-date" *ngIf="isSuperUser && !commonService.adminNews">
      {{ todayDate | dateSuffix }}
    </div>
    <div *ngIf="isSuperUser && commonService.adminNews" class="news_header">
      <h2 class="page-title">Admin - News</h2>
      <div class="news-date sm-hide" >{{ todayDate | dateSuffix }}</div>
      <a class="btn btn-icon" href="javascript:void(0);" (click)="opennewsfilter()">
          <img src="assets/img/SVG/filter_icon.svg" class="svg_icon" alt="">
      </a>
    </div>
  <div class="row">
    <div class="col-md-7">
      <div class="lastest_news" *ngIf="ToptrendingData && ToptrendingData.length > 0" >
        <div class="image-sec">
          <a [href]="ToptrendingData[0]?.top_url" target="_blank" *ngIf="ToptrendingData[0]?.top_type == 'Video'">
              <img src="assets/img/play-icon.png" class="play_icon">
          </a>
          <!-- <a [href]="ToptrendingData[0]?.top_url" target="_blank" *ngIf="ToptrendingData[0]?.top_type == 'Article'">
              <i class="fa fa-book" aria-hidden="true"></i>
          </a> -->
          <img [src]="this.ToptrendingData[0]?.top_image" alt="" (click)="gotoNews(ToptrendingData[0])"/>
        </div>
        <div (click)="gotoNews(ToptrendingData[0])">
          <span class="subtitle">{{ToptrendingData[0]?.top_source_name}}</span>
          <h2>{{ToptrendingData[0]?.top_title}}</h2>
          <p>{{ToptrendingData[0]?.top_text}}</p>
        </div>
      </div>
    </div>
    <div class="col-md-5 border-left">
      <div class="lastest_newslist">
        <ul *ngFor="let ToptrendingItem of ToptrendingData | slice: 1:4;" >
          <li>
            <span class="image-sec">
              <a [href]="ToptrendingItem?.top_url" target="_blank" *ngIf="ToptrendingItem?.top_type == 'Video'">
                <img src="assets/img/play-icon.png" class="play_icon">
              </a>
              <img [src]="ToptrendingItem?.top_image" alt="" (click)="gotoNews(ToptrendingItem)"/>
            </span>
            <div (click)="gotoNews(ToptrendingItem)">
              <span class="subtitle">{{ToptrendingItem?.top_source_name}}</span>
              <h3>{{ToptrendingItem?.top_title}}</h3>
              <p>{{ToptrendingItem?.top_text}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="feature_news" >
  <div class="container">
    <div class="feature_content">
      <div class="row">
        <div class="col-md-4" *ngFor="let ToptrendingItem of ToptrendingData | slice: 4:7;index as i" >
          <div class="feature_newscell">
            <div class="feature_newsimg">
              <a [href]="ToptrendingItem?.top_url" target="_blank" *ngIf="ToptrendingItem?.top_type == 'Video'">
                <img src="assets/img/play-icon.png" class="play_icon">
              </a>
              <img [src]="ToptrendingItem?.top_image" (click)="gotoNews(ToptrendingItem)"/>
            </div>
            <div (click)="gotoNews(ToptrendingItem)">
              <span class="subtitle">{{ToptrendingItem?.top_source_name}}</span>
              <h3>{{ToptrendingItem?.top_title}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="stocks_news" *ngIf="this.ToptrendingData?.length > 0">
  <div class="container">
    <div class="row" *ngFor="let ToptrendingItem of ToptrendingData | slice: 7:8;index as i" >
      <div class="col-md-6">
        <div class="stocks_newsimg">
          <a [href]="ToptrendingItem?.top_url" target="_blank" *ngIf="ToptrendingItem?.top_type == 'Video'">
            <img src="assets/img/play-icon.png" class="play_icon">
          </a>
          <img [src]="ToptrendingItem?.top_image" alt="" (click)="gotoNews(ToptrendingItem)"/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="stocks_newscontent" (click)="gotoNews(ToptrendingItem)">
          <span class="newssubtext">{{ToptrendingItem?.top_source_name}}</span>
              <h2>{{ToptrendingItem?.top_title}}</h2>
          <p>{{ToptrendingItem?.top_text}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

  <div class="all_newscell " id="companyProfile">
    <div class="container">
      <div class="masonry-inner ">
        <ngx-masonry class="bt_css">
          <ul >
            <li  ngxMasonryItem class="masonry-item" *ngFor="let ToptrendingItem of ToptrendingData | slice: 8:400;index as i">
              <div class="newsimg">
                <a [href]="ToptrendingItem?.top_url" target="_blank" *ngIf="ToptrendingItem?.top_type == 'Video'">
                  <img src="assets/img/play-icon.png" class="play_icon">
                </a>
                <img [src]="ToptrendingItem?.top_image" (click)="gotoNews(ToptrendingItem)"/>
              </div>
              <div (click)="gotoNews(ToptrendingItem)" #elementToFocus>
                <span class="newssubtext">{{ToptrendingItem?.top_source_name}}</span>
                <h3>{{ToptrendingItem?.top_title}}</h3>
              </div>
            </li>
          </ul>
        </ngx-masonry>
      </div>
    </div>
  </div>
</div>

<!-- Send Feedback Model Start -->
<div class="message_btn_fx">
    <button class="message_btn" type="button" data-toggle="modal" (click)="feedbackInput = '';"
      data-target="#message_bottom" (click)="gotoLoginPage()">
      <img src="../../assets/img/asset.png">
    </button>
  </div>
  <div class="modal fade add_queto_modal message_bottom" id="message_bottom" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md mob" role="document">
      <div class="modal-content" *ngIf="isLoggedIn">
        <div class="modal-header">
          <h2>We’d love to hear from you as we continue to improve our product!</h2>
          <button type="button" (click)="this.feedbackInput=''" class="close" data-dismiss="modal" aria-label="Close">
            <span><i class="fa fa-times" aria-hidden="true"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <!-- <form> -->
          <div class="form_inner">
            <textarea [(ngModel)]="feedbackInput" class="form-control"
              placeholder="Please let us know your thoughts..."></textarea>
          </div>
          <div class="add_termactin">
            <button type="button" data-dismiss="modal" [disabled]="!feedbackInput" aria-label="Close"
              (click)="saveFeedback()" class="btn btn-success">Submit</button>
            <button type="button" data-dismiss="modal" (click)="this.feedbackInput=''" aria-label="Close" class="btn btn-secondary">Cancel</button>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
  <!-- Send Feedback Model End (click)="close()"-->
  <div *ngIf="newsfilterpopup" class="backdrop"></div>
  <app-news *ngIf="newsfilterpopup" class="publispopup newwatch_pop com_profilenews">
    <div class="jw-modal">
      <div class="jw-modal-body">
        <div class="modal_header">
          <h2>Admin - News</h2>
          <button (click)="closenewsfilter()" class="close">
            <span>&times;</span>
          </button>
        </div>
        <!-- <div class="pubfl_serach">
          <button type="button" class="btn"><i class="fa fa-search" aria-hidden="true"></i></button>
          <input type="text" placeholder="Search Publisher"  class="form-control">
        </div> -->
        <div class="text-right"> 
          <span class="reset_filbtn" (click)="resetfilter(1)"><i class="fa fa-repeat"
              aria-hidden="true"></i> Reset Selection
            </span>
          </div>
        <div class="publrs_datawarp">
          <ng-container *ngFor="let newsItem of this.Publicerlist">
            <div class="publrs_dataitem" *ngIf="newsItem?.top_source_name">
              <label class="switch">
                <input type="checkbox" (change)="filternews($event,newsItem?.top_source_name)"
                  [checked]="Filterbynews2.indexOf(newsItem?.top_source_name) >= 0"/>
                <span class="slider round"></span>
              </label>
              <span>
                {{newsItem?.top_source_name}}
              </span>
            </div>
          </ng-container>
        </div>
        <div class="model_footer">
          <button type="button" data-dismiss="modal" aria-label="Close"
            class="btn btn-primary" (click)="applynewsfilters()">Apply Filters</button>
          <button type="button" data-dismiss="modal" aria-label="Close" 
            class="btn btn-light" (click)="closenewsfilter()">Cancel</button>
        </div>
      </div>
    </div>
  </app-news>