import { Component, OnInit, HostListener } from '@angular/core';
import { BaseAPIService } from 'src/app/Shared/Service/base-api.service';
import { AppSetting } from 'src/app/Shared/Model/app-setting.model';
import { CommonHelperService } from '../../Shared/Service/common-helper.service';
import { Router } from '@angular/router';
import { AccountsService } from 'src/app/Shared/Service/accounts.service';
import { DatePipe } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { Title } from "@angular/platform-browser";
import { WatchListService } from 'src/app/Shared/Service/watch-list.service';


@Component({
  selector: 'app-news-new',
  templateUrl: './news-new.component.html',
  styleUrls: ['./news-new.component.css']
})
export class NewsNewComponent implements OnInit {
  ToptrendingData;
  newsPublicerlist;
  todayDate: string;
  currentPage: number = 1;
  totalPage: number;
  limit = 20;
  feedbackInput = '';
  userInfo;
  isLoggedIn: boolean;
  showNewsPopup = false;
  selectedNews;
  isSuperUser: boolean = false;
  newsfilterpopup = false;
  Filterbynews2: any = [];
  Publicerlist: any = [];
  filterapplyed = false;
  reset = 1;

  constructor(
    public base: BaseAPIService,
    public readonly _commonService: CommonHelperService,
    public commonService: CommonHelperService,
    private readonly _router: Router,
    public _accountService: AccountsService,
    private datePipe: DatePipe,
    private readonly appCoponent: AppComponent,
    private titleService: Title,
    private readonly _watchListService: WatchListService,
    
  ) { 
    const currentDate = new Date();
    this.todayDate = currentDate.toDateString(); 
    this._accountService.isLoggedIn.subscribe(data => {
      this.isLoggedIn = data;
    });
    this.titleService.setTitle("Stock Market News - Manic Market - manicmarket.com");
  }

  ngOnInit() {
    this.ToptrendingNews();
    this.getActiveUser();
    this.getwatchlistnews(this.Filterbynews2);
  }
  
  ToptrendingNews() {
    this.base.get(`${AppSetting.toptrendingNews}?page=${this.currentPage}&limit=20`).subscribe((responseData) => {
      this.totalPage = responseData.total_page;
      this.ToptrendingData = responseData.response;
    });
  }

  loadMoreData() {
    if (this.currentPage < this.totalPage) {
      const nextPage = this.currentPage + 1;
      this.base.get(`${AppSetting.toptrendingNews}?page=${nextPage}&limit=20`).subscribe((responseData) => {
        this.currentPage = nextPage;
        this.ToptrendingData = [...this.ToptrendingData, ...responseData.response];
      });
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;
    const clientHeight = window.innerHeight;
    if ((scrollTop + clientHeight) >= scrollHeight - 1) {
      this.loadMoreData();
    }
  }

  saveFeedback() {
    const feedback = {
      feedback: this.feedbackInput
    }
    this.base.post(AppSetting.feebackCreate + (this.userInfo.id)+ '/', feedback).subscribe(() => {
      this._commonService.showSuccessAlert('Thank you for the feedback! Please continue to share any ideas to help improve the site!');
      this.feedbackInput = '';
    });
  }
  
  getActiveUser() {
    this.base.get(AppSetting.getProfile).subscribe((user) => {
      this.userInfo = user.data;
      if (this.userInfo && this.userInfo.is_superuser) {
        this.isSuperUser = true;
      }
    });
  }
  gotoLoginPage() {
    if ((!this.isLoggedIn)) {
      this._router.navigate(['/login']);
    }
  }
  gotoNews(ToptrendingData) {
  //  console.log(ToptrendingData.id)
    const date = this.datePipe.transform(ToptrendingData.top_date, 'yyyy-MM-dd');
    this._router.navigate(['all-news/' + "/ticker-news/" + date + "/" + ToptrendingData.news_title_slug]);
  }

  opennewsfilter() {
    this.newsfilterpopup = true;
  }
  closenewsfilter() {
    this.newsfilterpopup = false;
  }
  getwatchlistnews(filter) {
    this._watchListService.AllnewsByfilter(filter).subscribe(responseData => {
      if (responseData.success) {
        if (responseData?.response?.filter.length == 0) {
          responseData?.response?.news_publisher.map((val:any) => (this.Filterbynews2.push(val.top_source_name)))
        } else {
          this.Filterbynews2 = responseData?.response.filter;
        }
        this.Publicerlist = responseData.response.news_publisher;
      } else {
      }
    });
  }
  applynewsfilters() {
    this.closenewsfilter();
    this.getwatchlistnews(this.Filterbynews2);
    this.filterapplyed = true;
    setTimeout(()=>{  
      window.location.reload();
    }, 1000);
    
  }
  filternews(e, name) {
    if (e.target.checked) {
      this.Filterbynews2.push(name)
    } else {
      var i = this.Filterbynews2.indexOf(name);
      if (i >= 0) {
        this.Filterbynews2.splice(i, 1)
      }
    }
  }
  resetfilter(value) {
    if (value === 1) {
      this.Filterbynews2 = [];
      this.reset = 2;
      this.Publicerlist.map(res => {
        this.Filterbynews2.push(res?.top_source_name)
      })
    }
  }
}

