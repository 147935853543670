<div class="textarea" [style.width.px]='width' [ngClass]="edittopname ? 'active topfive_popup' : 'topfive_popup' "
  cdkDrag [cdkDragDisabled]="edittopname || !dragtopsix" *ngIf="this.login && this.testarray.length > 0">
  <button type="button" (click)="edittoplist()"
    [ngClass]="edittopname ? 'selected_top_active edittopfive' : 'edittopfive' ">
    <i class="fa fa-pencil" aria-hidden="true"></i>
  </button>
  <ul #dropListContainer cdkDropListGroup
    [ngClass]="(width >0 && width <= 360) ? 'drag_xs' : (width >360 && width <= 450) ? 'drag_sm' : (width >451 && width <= 900) ? 'drag_md' : 'drag_lg'  ">
    <li *ngFor="let item of this.testarray; let i = index;" id="{{'topfiveid'+i}}" cdkDropList [cdkDropListData]="i">
      <div cdkDragBoundary=".topfive_popup" cdkDrag [cdkDragDisabled]="!edittopname" [cdkDragData]="i"
        (cdkDragEntered)="dragEntered($event)" (cdkDragMoved)="dragMoved($event)"
        (cdkDragDropped)="dragDropped($event)">
        <!-- api data show start -->
        <h3 [ngClass]="{'text-success': TopSocketdata[item]?.changePercent>0, 'text-warning': TopSocketdata[item]?.changePercent<0 }" *ngIf="apitopShow">
          <a style="cursor: pointer;" (click)="navigateAndReload(TopSocketdata[item]?.symbol)">
            {{TopSocketdata[item]?.symbol}}
          </a>
          <!-- Post Market Start -->
          <span *ngIf="TopSocketdata2[item]?.is_market_open == false && TopSocketdata2[item]?.pre_market_open != true && TopSocketdata2[item]?.post_market_open == false" [ngClass]="{'text-success': (TopSocketdata[item]?.postMarketChangePercent >  0), 'text-warning': (TopSocketdata[item]?.postMarketChangePercent <  0)}">
            <span>
              {{TopSocketdata[item]?.postMarketChangePercent > 0 ? '+' : '' }}{{(TopSocketdata[item]?.postMarketChangePercent ||
                TopSocketdata[item]?.postMarketChangePercent == 0) ? ((TopSocketdata[item]?.postMarketChangePercent) |
                number:'.2-2')+'%':"N/A" }}
            </span>
          </span>
          <!-- Post Market End -->

          <!-- Post Market true Start -->
          <span *ngIf="TopSocketdata2[item]?.post_market_open == true" [ngClass]="{'text-success': (TopSocketdata[item]?.postMarketChangePercent >  0), 'text-warning': (TopSocketdata[item]?.postMarketChangePercent <  0)}">
            <span >
              {{TopSocketdata[item]?.postMarketChangePercent > 0 ? '+' : '' }}{{(TopSocketdata[item]?.postMarketChangePercent ||
                TopSocketdata[item]?.postMarketChangePercent == 0) ? ((TopSocketdata[item]?.postMarketChangePercent) |
                number:'.2-2')+'%':"N/A" }}
            </span>
        </span>
        <!-- Post Market true End -->
          
          <!-- Pre Market Start --> 
          <span *ngIf="TopSocketdata2[item]?.is_market_open == false && TopSocketdata2[item]?.pre_market_open == true" [ngClass]="{'text-success': (TopSocketdata[item]?.preMarketChangePercent >  0), 'text-warning': (TopSocketdata[item]?.preMarketChangePercent <  0)}">
            <span>
              {{TopSocketdata[item]?.preMarketChangePercent > 0 ? '+' : '' }}{{(TopSocketdata[item]?.preMarketChangePercent ||
                TopSocketdata[item]?.preMarketChangePercent == 0) ? ((TopSocketdata[item]?.preMarketChangePercent) |
                number:'.2-2')+'%':"N/A" }} 
            </span>
           </span>
           <!-- pre Market End -->
        </h3>
        <!-- api data show start -->

        <!-- yahoo websocket data show start -->
        <h3 class="border-none" *ngIf="websocketSHow"> 
          <a style="cursor: pointer;" (click)="navigateAndReload(TopSocketdata[item]?.symbol)">
            {{TopSocketdata[item]?.symbol}}
          </a>
          <!-- Post Market Start -->
          <span *ngIf="yahooData[item]?.marketHours == '2'" [ngClass]="{'text-success': (yahooData[item]?.changePercent >  0), 'text-warning': (yahooData[item]?.changePercent <  0)}">
            <span>
              {{yahooData[item]?.changePercent > 0 ? '+' : '' }}{{(yahooData[item]?.changePercent ||
                yahooData[item]?.changePercent == 0) ? ((yahooData[item]?.changePercent) |
                number:'.2-2')+'%':"N/A" }}
            </span>
          </span>
          <!-- Post Market End -->
          
          <!-- Pre Market Start --> 
          <span *ngIf="yahooData[item]?.marketHours == '0'" [ngClass]="{'text-success': (yahooData[item]?.changePercent >  0), 'text-warning': (yahooData[item]?.changePercent <  0)}">
            <span>
              {{yahooData[item]?.changePercent > 0 ? '+' : '' }}{{(yahooData[item]?.changePercent ||
                yahooData[item]?.changePercent == 0) ? ((yahooData[item]?.changePercent) |
                number:'.2-2')+'%':"N/A" }} 
            </span>
           </span>
           <!-- pre Market End -->
        </h3>
        <div *ngIf="websocketSHow && yahooData[item]?.marketHours != '1'" class="tp_status" [ngClass]="{'text-success': TopSocketdata[item]?.changePercent>0, 'text-warning': TopSocketdata[item]?.changePercent<0 }"></div>
          
        <div *ngIf="websocketSHow && yahooData[item]?.marketHours == '1'" class="tp_status" [ngClass]="{'text-success': yahooData[item]?.changePercent>0, 'text-warning': yahooData[item]?.changePercent<0 }"></div>
        <!-- yahoo websocket data show start -->
        <div class="d-flex justify-content-between mt-1">
          <p> 
            <span *ngIf="yahooData[item]?.marketHours != '1'">
              {{TopSocketdata[item]?.latestVolume ? (TopSocketdata[item]?.latestVolume | AmountFormat: 2): 'N/A'}}
            </span>
            <span *ngIf="yahooData[item]?.marketHours == '1'">
              {{yahooData[item]?.dayVolume ? (yahooData[item]?.dayVolume | AmountFormat: 2): 'N/A'}}
            </span> |
            {{TopSocketdata[item]?.avgTotalVolume ? (TopSocketdata[item]?.avgTotalVolume | AmountFormat: 2): 'N/A'}}
          </p>
          <p>{{TopSocketdata[item]?.MarketCap?(TopSocketdata[item]?.MarketCap | AmountFormat: 2):'N/A'}} |
            {{TopSocketdata[item]?.peRatio? (TopSocketdata[item]?.peRatio | number : '.2-2'):'N/A'}}
          </p>
        </div>
        <div class="d-flex top_soket_pr"  [ngClass]="{'text-success': TopSocketdata[item]?.changePercent>0, 'text-warning': TopSocketdata[item]?.changePercent<0 }" *ngIf="yahooData[item]?.marketHours != '1'">
          <span class="font26">
            {{TopSocketdata[item]?.changePercent>0?
            '+': ''}}{{(TopSocketdata[item]?.changePercent || TopSocketdata[item]?.changePercent == 0) ?
            ((TopSocketdata[item]?.changePercent) | number : '1.2-2' ): 'N/A'}}%</span>
          <div class="top_sub">
            <span>
              {{TopSocketdata[item]?.changePercent  > 0  && TopSocketdata[item]?.change ? '$+' :'$'}}{{TopSocketdata[item]?.change ? (TopSocketdata[item]?.change | number : '.2-2'):'N/A'}}
            </span>
            <p class="">${{TopSocketdata[item]?.latestPrice ? (TopSocketdata[item]?.latestPrice | number : '.2-2') :'N/A'}}</p>
          </div>
        </div>

        <!-- yahoo websoket data show -->
        <div class="d-flex top_soket_pr"  [ngClass]="{'text-success': yahooData[item]?.changePercent>0, 'text-warning': yahooData[item]?.changePercent<0 }" *ngIf="yahooData[item]?.marketHours == '1'">
          <span class="font26">
            {{yahooData[item]?.changePercent>0?
            '+': ''}}{{(yahooData[item]?.changePercent || yahooData[item]?.changePercent == 0) ?
            ((yahooData[item]?.changePercent) | number : '1.2-2' ): 'N/A'}}%</span>
          <div class="top_sub">
            <span>
              {{yahooData[item]?.change  > 0  && yahooData[item]?.change ? '$+' :'$'}}{{yahooData[item]?.change ? (yahooData[item]?.change | number : '.2-2'):'N/A'}}
            </span>
            <p class="">${{yahooData[item]?.price ? (yahooData[item]?.price | number : '.2-2') :'N/A'}}</p>
          </div>
        </div>
        <!-- yahoo websoket data show end -->
        
        <button type="button" class="rmv_t5" *ngIf="edittopname"><i class="fa fa-times" aria-hidden="true"
            (click)="removewtachlist(TopSocketdata[item]?.id)"></i></button>
      </div>
    </li>
  </ul>
  <div class="grabber" (mouseover)="dragtopsix=false" (mouseout)="dragtopsix=true" (mousedown)="onMouseDown($event)"></div>
</div>
