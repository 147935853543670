<!-- Change Percentage Bar View ALL Page Strat !hasRoute('watchlist')-->
<!--  *ngIf="isShowLogo && !hasRoute('watchlist') && !hasRoute('company-profile')" -->
<div class="cp_info_first desktop_headerbar homeHeader" *ngIf="!isShowLogo" [ngClass]="{'myClass': !isShowLogo}">
  <div class="container-fluid">
    <div class="col-md-9 cp_info">
      <div class="cpinfo_head topheaddata_bar">
        <ul>
          <li class="item red top_css" *ngFor="let item of changeFinance;let i=index" [ngClass]=" item.symbol == 'US05Y' ? 'symbol_none' : 'cp'" [routerLink]="['/company-profile', this.changegoPage[i]?.symbol]">
            <div *ngIf="item.symbol !== 'US05Y' && item.symbol !== 'US30Y' && this.changegoPage[i]?.symbol !== 'US05Y' && this.changegoPage[i]?.symbol !== 'US30Y'">
              <span class="border-space">&nbsp;|&nbsp;</span>
              <span class="copany_short" (click)="mmmmmmm()" >{{item.symbol}}&nbsp;</span>
              <span class="" [ngClass]="{'company_value': (item?.changePercent >  0), 'text-warning': (item?.changePercent <  0)}"> 
                {{item?.changePercent > 0? '': ''}} {{item?.changePercent > 0? '+': ''}} {{(item?.changePercent || item?.changePercent == 0) ? ((item?.changePercent ) | number : '1.2-2' ): 'N/A'}}% </span>
              <!-- <span class="" [ngClass]="{'company_value': (item?.changePercent >  0), 'text-warning': (item?.changePercent <  0)}"> {{item?.changePercent > 0? '': ''}} {{item?.changePercent > 0? '+': ''}} {{(item?.changePercent || item?.changePercent == 0) ? ((item?.changePercent ) | number : '1.2-2' ): 'N/A'}}% </span> -->
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- Change Percentage Bar View All Page End -->

<!-- Mobile - Show Indices on Homepage Start -->
<div class="cp_info_first desktop_headerbar mobile_logo" *ngIf="isShowLogo">
  <div class="container-fluid">
    <div class="col-md-9 cp_info">
      <div class="cpinfo_head topheaddata_bar">
        <ul>
          <li class="item red top_css" *ngFor="let item of changeFinance;let i=index" [ngClass]=" item.symbol == 'US05Y' ? 'symbol_none' : 'cp'" [routerLink]="['/company-profile', this.changegoPage[i]?.symbol]">
            <div *ngIf="item.symbol !== 'US05Y' && item.symbol !== 'US30Y' && this.changegoPage[i]?.symbol !== 'US05Y' && this.changegoPage[i]?.symbol !== 'US30Y'">
              <span class="border-space">&nbsp;|&nbsp;</span>
              <span class="copany_short" (click)="mmmmmmm()">{{item.symbol}}&nbsp;</span>
              <span class="" [ngClass]="{'company_value': (item?.changePercent >  0), 'text-warning': (item?.changePercent <  0)}"> 
                {{item?.changePercent > 0? '': ''}} {{item?.changePercent > 0? '+': ''}} {{(item?.changePercent || item?.changePercent == 0) ? ((item?.changePercent ) | number : '1.2-2' ): 'N/A'}}% </span>
              <!-- <span class="" [ngClass]="{'company_value': (item?.changePercent >  0), 'text-warning': (item?.changePercent <  0)}"> {{item?.changePercent > 0? '': ''}} {{item?.changePercent > 0? '+': ''}} {{(item?.changePercent || item?.changePercent == 0) ? ((item?.changePercent ) | number : '1.2-2' ): 'N/A'}}% </span> -->
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- Mobile - Show Indices on Homepage End -->
  <header id="header"   [ngClass]="currentRoute==='/mobilechat'?'mobileChatHeader site_header':'site_header'">
    <!-- Mobile Size In Side Nav Strat -->
    <div class="xs-navbar" *ngIf="this.isLoggedIn && isTab">
      <div *ngIf="_userDetail" class="xs_navheade">
        <span class="close" (click)="closeMenu()">&times;</span>
        <strong *ngIf="_userDetail" class="xs_username">{{_userDetail.username}}</strong>
        <img class="usr_defult" *ngIf="!_userDetail.image" src="../../assets/img/money-bag.png" alt="">
        <img class="Img" *ngIf="_userDetail.image" [src]="_userDetail.image" alt="">
      </div>
      <ul class="xs-navmenu">
        <li (click)="gotoPageTab('profile')">
          <a href="javascript:void(0);" (click)="mmmmmmm()">My Account</a>
        </li>
        <li type="button" data-toggle="modal" data-target="#message_bottom" (click)="gotoLoginPage()">
          <a href="javascript:void(0);">Leave Feedback</a>
        </li>
        <!-- <li (click)="gotoPageTab('watchlist')">
          <a href="javascript:void(0);">My watchlists</a>
        </li> -->
        <!-- <li (click)="gotoPageTab('screener')"><a href="javascript:void(0);">Screener</a></li> -->
        <li *ngIf="isSuperUser" (click)="gotoPageTab('user-management')">
          <a href="javascript:void(0);">Admin-User Mgt</a>
        </li>
        <li *ngIf="isSuperUser" (click)="gotoPageTab('investor-dictionary')">
          <a href="javascript:void(0);">Admin-Dictionary</a>
        </li>
        <li *ngIf="isSuperUser" (click)="gotoPageTab('quotes')">
          <a href="javascript:void(0);">Admin-Quotes</a>
        </li>
        <li *ngIf="isSuperUser" (click)="gotoPageTab('')">
          <a href="javascript:void(0);">Admin- News</a>
        </li>
        <li (click)="userLogout()">
          <a href="javascript:void(0);">Log out</a>
        </li>
      </ul>
    </div>
    
    <!-- Mobile Size In Side Nav End -->
    <div class="container-fluid">
      <div class="row sx_headtop">
        <div *ngIf="isMobile" class="col-md-2 head_serchearp">
           <!-- <button class="xs_serachbtn btn btn-outline-dark"><i class="fa fa-search"></i></button> -->
           <!-- <button *ngIf="!isShowLogo" class="message_btn" type="button" data-toggle="modal" data-target="#message_bottom" (click)="gotoLoginPage()">
            <img src="../../assets/img/asset.png">
          </button> -->
          
  
          <!-- <a *ngIf="isShowLogo" class="head_back_arrow" routerLink="">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </a> -->
          <!-- <li *ngIf="this.login" (click)="gotoPage('side-bar','tab6')" id="tab6">
            <a href="javascript:void(0);" id="my_profile"><img src="../../assets/img/mf_userprofile.png" class="mf_icon">Profile</a>
          </li> -->
        </div>
        <div class="col-md-2 head_left mobile_logo">
          <a routerLink="" *ngIf="isShowLogo"><img src="assets/img/logo.png" class="logo" alt=""></a>
        </div>
        <div class="col-md-2 head_left lg-hide">
          <a routerLink="" (click)="mmmmmmm()" *ngIf="isShowLogo"><img src="assets/img/logo.png" class="logo" alt=""></a>
        </div>
        
        <!-- mobile_head_sercbox display always for web -->
        <div class="col-md-5 head_sercbox xs-hide" id="search-bar">
          <form (ngSubmit)="getSearchData()" *ngIf="isShowSearchBar">
            <div class="form-group">
              <!-- <i class="fa fa-search"></i> -->
              <!-- Add function input clear  (click)="clearSearch()" -->
              <ng-select #ngSelectSearch class="form-control search-bar" placeholder="Enter Company Name or Trading Symbol" typeahead [searchable]="true" [ngModelOptions]="{standalone: true}" (search)="searchValue=$event?.term;getSearchData()" [clearable]="true" [(ngModel)]="searchValue"  (clear)="onClear()" clearAllText="" (keyup.enter)="redirectToRoute(searchValue)" (click)="redirectToRoute(searchValue)" (focus)="showHideOptions();" (blur)="isOpenSearchBar=false;">
                <ng-container *ngIf="searchValue">
                  <ng-option [value]="item.value" *ngFor="let item of select2Model">
                      <img [src]="item.changePercent > 0 ? '../../assets/img/market_up.png' : item.changePercent < 0 ? '../../assets/img/market_down.png' : '../../assets/img/no_dataupdate.png'">
                    <a class="a-no-decore" (click)="redirectToRoute(item?.value.symbol)" (keyup.enter)="redirectToRoute(item?.value.symbol)" (change)="redirectToRoute(item?.value.symbol)">
                      <strong>{{item?.value.symbol}}</strong> <small>{{item.label}}</small>
                    </a>
                  </ng-option>
                </ng-container>
                <ng-container *ngIf="!searchValue && trendingList.length> 0">
                  <ng-option><span class="tranding_text">Trending Today tet</span></ng-option>
                  <ng-container *ngFor="let item of trendingList">
                    <ng-option [value]="item.value">
                      <img 
                        [src]="item.changePercent > 0 ? '../assets/img/market_up.png' : item.changePercent < 0 ? '../assets/img/market_down.png' : '../assets/img/no_dataupdate.png' ">
                      <a class="a-no-decore"
                        (change)="redirectToRoute(item?.value.symbol)">
                      <strong>{{item.value.symbol}}</strong>
                      </a> 
                    </ng-option>
                  </ng-container>
                </ng-container>
              </ng-select>
              <button type="submit" (click)="getSearchedFirstSymbol($event)" [disabled]="isLoading" class="btn btn-light mr-3">{{!isLoading?
                'Search':'Searching..'}}</button>
              <a class="btn btn-light" [routerLink]="['/company-profile', randomSymbol]"  (click)="openRandomComp()" (click)="reloadWindow()">
                Explore
              </a>
            </div>
          </form>
        </div>
        
        <div *ngIf="isMobile" class="col-md-5 head_right">
          <a class="pro_df" (click)="gotoLoginPage()" (click)="clickEvent()" id="tab6">
            <span class="headusr_icon" id="my_profile">
              <img class="usr_defult" *ngIf="!_userDetail.image" src="../../assets/img/money-bag.png" alt="">
              <img *ngIf="_userDetail.image" [src]="_userDetail.image" alt="">
            </span>
          </a>
        </div>
        
        <!-- <div *ngIf="isMobile" class="col-md-5 head_right">
          <a class="pro_df" (click)="gotoLoginPage()" (click)="gotoPage('side-bar', 'tab6')" id="tab6">
            <span class="headusr_icon" id="my_profile">
              <img class="usr_defult" *ngIf="!_userDetail.image" src="../../assets/img/money-bag.png" alt="">
              <img *ngIf="_userDetail.image" [src]="_userDetail.image" alt="">
            </span>
          </a>
        </div> -->
        
        <!-- <div *ngIf="isMobile" class="col-md-5 head_right">
          <a class="pro_df" [matMenuTriggerFor]="menu">
            <span class="headusr_icon" id="my_profile">
              <img class="usr_defult" *ngIf="!_userDetail.image" src="../../assets/img/money-bag.png" alt="">
              <img *ngIf="_userDetail.image" [src]="_userDetail.image" alt="">
            </span>
          </a>
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item routerLink="profile">My Account</button>
          <button mat-menu-item data-toggle="modal" data-target="#message_bottom" (click)="gotoLoginPage()">Leave Feedback</button>
        </mat-menu> -->
        <div *ngIf="!isMobile" class="col-md-5 head_right" [ngClass]="{'logo_add_class': isHomePage}">
          <ul>
            <li>
              <a (click)="openNormalNews()">News</a>
            </li>
            <li>
              <a routerLink="watchlist">Watchlists</a>
            </li>
            <li>
              <a routerLink="screener" (click)="onClickScreener()">Screener</a>
            </li>
            <li>
              <a (click)="openNormalDic()">Dictionary</a>
            </li>
            <li>
              <a (click)="openNormalQuotes()">Quotes</a>
            </li>
          </ul>
          <a *ngIf="!(isAuthenticated | async)" class="btn btn-outline-dark" (click)="gotologinpage()">Log In</a>
          <div class="header_username" *ngIf="isAuthenticated | async">
            <!-- <ng-container *ngIf="this._userDetail.first_name ||this._userDetail.last_name; else userName">
              {{this._userDetail.first_name+" " +this._userDetail.last_name}}
            </ng-container>
            <ng-template #userName>
              <span> {{this._userDetail.username}}</span>
            </ng-template> -->
            <!-- <img *ngIf="!_userDetail.image" src="assets/img/avatar.png" alt=""> -->
            <span class="headusr_icon">
              <img class="usr_defult" *ngIf="!_userDetail.image" src="../../assets/img/money-bag.png" alt="">
              <img class="user_img" *ngIf="_userDetail.image" [src]="_userDetail.image" alt="">
            </span>
            <ul class="usrdropdown">
              <li>
                <a (click)="profileUpdate()">My Account</a>
              </li>
              <li type="button" data-toggle="modal" data-target="#message_bottom" (click)="gotoLoginPage()">
                <a href="javascript:void(0);">Leave Feedback</a>
              </li>
              <li *ngIf="this._accountService?.userDetail?._value?.is_superuser">
                <a href="javascript:void(0);" (click)="gotoUserManagement()">User Management</a>
              </li>
              <li *ngIf="this._accountService?.userDetail?._value?.is_superuser">
                <a (click)="gotoAdminDic()" href="javascript:void(0);"> Admin Dictionary</a>
              </li>
              <li *ngIf="this._accountService?.userDetail?._value?.is_superuser">
                <a (click)="gotoAdminQuotes()" href="javascript:void(0);"> Admin Quotes</a>
              </li>
              <li *ngIf="this._accountService?.userDetail?._value?.is_superuser">
                <a (click)="gotoAdminNews()" href="javascript:void(0);"> Admin News</a>
              </li>
              <li>
                <a href="javascript:void(0);" (click)="userLogout()">log Out</a>
              </li>
              <!-- <li><a href="javascript:Void(0);" data-toggle="modal" data-target="#error_model">Error</a></li> -->
            </ul>
          </div>
        </div>
      </div>
      
      <div class="row lg-hide">
        <!-- mobile_head_sercbox display always for web -->
        <div class="col-md-12 head_sercbox" id="search-bar">
          <form (ngSubmit)="getSearchData()" *ngIf="isShowSearchBar">
            <div class="form-group">
              <ng-select  (click)="clearSearch()" #ngSelectSearch class="form-control search-bar" bindLabel="name"
              placeholder="Enter Company Name or Trading Symbol" [searchable]="true"
              [ngModelOptions]="{standalone: true}" (change)="redirectToRoute2(searchValue)"
              (search)="searchValue=$event?.term;getSearchData()" (keydown)="searchValue=$event?.term;getSearchData()" [clearable]="true" [(ngModel)]="searchValue"
              appendTo="body" (focus)="showHideOptions();" (blur)="isOpenSearchBar=false;"
              (clear)="onClear()" clearAllText="" onfocus='blur()'>
                <ng-container *ngIf="searchValue">
                  <ng-option [value]="item.value" *ngFor="let item of select2Model">
                    <img [src]="item.changePercent > 0 ? '../../assets/img/market_up.png' : item.changePercent < 0 ? '../../assets/img/market_down.png' : '../../assets/img/no_dataupdate.png' ">
                    <a class="a-no-decore" (change)="redirectToRoute2(item?.value.symbol)">
                      <strong>{{item?.value.symbol}}</strong> <small>{{item.label}}</small>
                    </a>
                  </ng-option>
                </ng-container>
                <ng-container *ngIf="!searchValue && trendingList.length > 0">
                  <ng-option> <span class="tranding_text">Trending Today</span></ng-option>
                  <ng-container *ngFor="let item of trendingList">
                    <ng-option [value]="item.value">
                      <img *ngIf="headerdata?.marketHours != 1" [src]="item.changePercent > 0 ? '../assets/img/market_up.png' : item.changePercent < 0 ? '../assets/img/market_down.png' : '../assets/img/no_dataupdate.png'">
                      <a class="a-no-decore" (change)="redirectToRoute2(item?.value.symbol)" >
                        <strong>{{item.value.symbol}}</strong>
                      </a>
                      <img *ngIf="headerdata?.marketHours == 1" [src]="headerdata?.changePercent > 0 ? '../assets/img/market_up.png' : headerdata?.changePercent < 0 ? '../assets/img/market_down.png' : '../assets/img/no_dataupdate.png'">
                      <a class="a-no-decore" (change)="redirectToRoute2(item?.value.symbol)" >
                        <strong>{{item.value.symbol}}</strong>
                      </a>
                    </ng-option>
                  </ng-container>
                </ng-container>
              </ng-select>
              <!-- <button type="submit" [disabled]="isLoading" class="btn btn-light mr-3">{{!isLoading?
                'Search':'Searching..'}}</button> -->
                <button type="submit" (click)="getSearchedFirstSymbol($event)" [disabled]="isLoading" class="btn btn-light mr-3"><i class="fa fa-search"></i></button>
                <a class="btn btn-light" [routerLink]="['/company-profile', randomSymbol]"  (click)="openRandomComp()" (click)="reloadWindow()">
                  Explore
                </a>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="cp_info_first desktop_headerbar lg-hide" *ngIf="isShowLogo !hasRoute('')">
      <div class="container-fluid">
        <div class="col-md-9 cp_info">
          <div class="cpinfo_head topheaddata_bar">
            <ul>
              <li class="item red top_css" *ngFor="let item of changeFinance;let i=index" [ngClass]=" item.symbol == 'US05Y' ? 'symbol_none' : 'cp'" [routerLink]="['/company-profile', this.changegoPage[i]?.symbol]">
                <div *ngIf="item.symbol !== 'US05Y' && item.symbol !== 'US30Y' && this.changegoPage[i]?.symbol !== 'US05Y' && this.changegoPage[i]?.symbol !== 'US30Y'">
                  <span class="border-space">&nbsp;|&nbsp;</span>
                  <span class="copany_short" (click)="mmmmmmm()">{{item.symbol}}&nbsp;</span>
                  <span class="" [ngClass]="{'company_value': (item?.changePercent >  0), 'text-warning': (item?.changePercent <  0)}"> 
                    {{item?.changePercent > 0? '': ''}} {{item?.changePercent > 0? '+': ''}} {{(item?.changePercent || item?.changePercent == 0) ? ((item?.changePercent ) | number : '1.2-2' ): 'N/A'}}%</span>
                  <!-- <span class="" [ngClass]="{'company_value': (item?.changePercent >  0), 'text-warning': (item?.changePercent <  0)}"> {{item?.changePercent > 0? '': ''}} {{item?.changePercent > 0? '+': ''}} {{(item?.changePercent || item?.changePercent == 0) ? ((item?.changePercent ) | number : '1.2-2' ): 'N/A'}}% </span> -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
  